<template>
<div class="content">
  <b-table striped hover responsive :items="users" :fields="fields">
    <template #cell(google_id)="data">
        <img v-if="data.item.google_id" src="/images/google.png" width="15" height="15" alt="Google" />
    </template>
    <template #cell(patreon_id)="data">
        <img v-if="data.item.patreon_id" src="/images/patreon2.png" width="15" height="15" alt="Google" />
    </template>
    <template #cell(created_at)="data">
      {{ data.item.created_at | moment("DD MMM YYYY @ HH:mm") }}
    </template>
    <template #cell(actions)="data">
      <div class="text-center">
        <a href="javascript:void(0);" @click="deleteUser(data.item.id)"><b-icon icon="trash" variant="danger"></b-icon></a>
      </div>
    </template>
  </b-table>

</div>
</template>

<script>
export default {
    name: "AdminUsers",
    data() {
      return {
        users: [],
        fields: [
          {
            key: 'id',
            label: '#',
            sortable: true
          },
          {
            key: 'name',
            sortable: true
          },
          {
            key: 'email',
            sortable: true
          },
          {
            key: 'google_id',
            label: '',
          },
          {
            key: 'patreon_id',
            label: '',
          },
          {
            key: 'is_admin',
            label: 'Admin',
            sortable: true
          },
          {
            key: 'created_at',
            label: 'Created at',
            sortable: true
          }, 
          {
            key: 'actions',
            label: 'Actions',
            class: 'text-center'
          }
        ]
      };
    },
    created() {
      this.$http.get('/admin/users')
        .then(response => {
          this.users = response.data.data;
        });
    },
    methods: {
      deleteUser(id) {
        
        this.$http.delete('/admin/users/' + id)
          .then(() => {
            this.$http.get('/admin/users')
              .then(response => {
                this.users = response.data.data;
              });
          });
      }
    }
}
</script>

<style lang="scss" scoped>

</style>
