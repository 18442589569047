<template>
  <div class="container-fluid">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/admin/users">Users</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/admin/handouts">Handouts</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" to="/admin/stats">Stats</router-link>
      </li>
    </ul>
    <div class="page">
      <div class="row justify-content-center">
          <div class="col-12">
            <AdminUsers />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminUsers from '@/components/admin/AdminUsers.vue';

export default {
    name: "Admin",
    components: { AdminUsers },
    data() {
      return {
        email: '',
        password: ''
      };
    },
    methods: {
      login() {
        // Handle login logic here
        this.$http.get('/sanctum/csrf-cookie').then(() => {
            this.$http.post('/login', {
              email: this.email,
              password: this.password
            })
              .then(() => {
                window.location.href = '/';
              });
        });
      }
    }
}
</script>

<style lang="scss" scoped>
.nav-tabs {
  margin-top:10px;
  position:relative;
  left: -1px;

  a {
    color:#6D832F;
  }
}

.container {
  margin-top:20px;
}

.page {
    padding:20px;
    font-size:20px;
    line-height:28px;
    background:#fff;
    margin-top:0;
    margin-bottom:20px;
    -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
    box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.15);
}
</style>
