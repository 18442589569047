<template>
  <div class="container page">
    <div class="row justify-content-center">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">Login</div>
                <div class="card-body">
                    <form @submit.prevent="login">
                      <div class="form-group">
                        <label for="email">Email</label>
                        <input type="email" class="form-control" id="email" v-model="email" placeholder="Enter email">
                      </div>
                      <div class="form-group">
                        <label for="password">Password</label>
                        <input type="password" class="form-control" id="password" v-model="password" placeholder="Enter password">
                      </div>
                      <button type="submit" class="btn btn-block btn-primary">Login</button>
                    </form>                    
                    
                    
                    <div class="text-center">
                      <h4>OR</h4>

                      <div class="social-login">
                        <LoginGoogle type="Login"></LoginGoogle>
                        <LoginPatreonV2 type="Login"></LoginPatreonV2>
                      </div>
                    </div>

                </div>
            </div>
            <p>&nbsp;</p>
            <p><router-link to="/forgot-password">Forgot your password?</router-link></p>
            <p>Don’t have an account? <router-link to="/register">Register now!</router-link></p>
            
        </div>
    </div>
  </div>
</template>

<script>
import LoginPatreonV2 from '@/components/LoginPatreonV2.vue';
import LoginGoogle from '@/components/LoginGoogle.vue';

export default {
    name: "Login",
    components: { LoginPatreonV2, LoginGoogle },
    data() {
      return {
        email: '',
        password: ''
      };
    },
    methods: {
      login() {
        if (this.email && this.password) {
          this.$http.get('/sanctum/csrf-cookie').then(() => {
              this.$http.post('/login', {
                email: this.email,
                password: this.password
              })
                .then(() => {
                  window.location.href = '/';
                });
          });  
        } else {
          this.$toast.error('Please enter your email and password.');
        }
      }
    }
}
</script>

<style lang="scss" scoped>
h4 {
  margin: 20px 0;
}

#google_btn {
  margin-bottom: 10px;
}
</style>
